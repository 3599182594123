<template>
  <div class="sidebar-menu">
    <v-subheader v-if="name" class="mt-2">{{ name }}</v-subheader>

    <template v-for="(item, index) in items">
      <template v-if="item.items.length >= 1">
        <v-list-group :key="`${name}-group-${index}`" color="white" no-action>
          <template #activator>
            <v-list-item-action>
              <v-icon>mdi-{{ item.icon.replace('fa-', '') }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>

          <SidebarModule v-for="(subItem, i) in item.items" :key="i" :item="subItem" />
        </v-list-group>
      </template>

      <SidebarModule v-else :key="index" :item="item" />
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SidebarModuleNav',
  components: {
    SidebarModule: _ => import('@/components/Sidebar/Module'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
</script>
